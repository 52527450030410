/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
html {
  height: 100%; }

body {
  height: 100%; }

@media screen and (max-width: 768) {
  *:hover {
    transform: scale(0) !important; } }

::-webkit-scrollbar {
  width: 1.5px;
  height: 1px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0c00000;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f0f1f8;
  border-radius: 50px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e9ebff; }

.primary-selection {
  background: #341e48; }

*:focus {
  outline: none; }

/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Responsiveness*/
/*Theme Colors*/
/*Borders*/
/* Main Theme Colors*/
.font-gray-60 {
  color: #f8f8f8; }

.font-gray-100 {
  color: #f0f0f0; }

.font-gray-150 {
  color: #e1e1e1; }

.font-gray-200 {
  color: #d8d8d8; }

.font-gray-250 {
  color: #b3b3b3; }

.font-gray-300 {
  color: #6d6d6d; }

.font-gray-350 {
  color: #747474; }

.font-gray-400 {
  color: #424242; }

.font-gray-500 {
  color: #332e35; }

.font-gray-600 {
  color: #323232; }

.font-gray-650 {
  color: #373739; }

.font-gray-800 {
  color: #363430; }

.font-gray-900 {
  color: #2b2b2d; }

.font-gray-1000 {
  color: #171718; }

.bg-gray-60 {
  background-color: #f8f8f8; }

.bg-gray-100 {
  background-color: #f0f0f0; }

.bg-gray-150 {
  background-color: #e1e1e1; }

.bg-gray-200 {
  background-color: #d8d8d8; }

.bg-gray-250 {
  background-color: #b3b3b3; }

.bg-gray-300 {
  background-color: #6d6d6d; }

.bg-gray-350 {
  background-color: #747474; }

.bg-gray-400 {
  background-color: #424242; }

.bg-gray-500 {
  background-color: #332e35; }

.bg-gray-600 {
  background-color: #323232; }

.bg-gray-650 {
  background-color: #373739; }

.bg-gray-800 {
  background-color: #363430; }

.bg-gray-900 {
  background-color: #2b2b2d; }

.bg-gray-1000 {
  background-color: #171718; }

.border-gray-60 {
  border-color: #f8f8f8; }

.border-gray-100 {
  border-color: #f0f0f0; }

.border-gray-150 {
  border-color: #e1e1e1; }

.border-gray-200 {
  border-color: #d8d8d8; }

.border-gray-250 {
  border-color: #b3b3b3; }

.border-gray-300 {
  border-color: #6d6d6d; }

.border-gray-350 {
  border-color: #747474; }

.border-gray-400 {
  border-color: #424242; }

.border-gray-500 {
  border-color: #332e35; }

.border-gray-600 {
  border-color: #323232; }

.border-gray-650 {
  border-color: #373739; }

.border-gray-800 {
  border-color: #363430; }

.border-gray-900 {
  border-color: #2b2b2d; }

.border-gray-1000 {
  border-color: #171718; }

/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
.primary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background: #2a192d;
  border-radius: 4px; }
  .primary-cta:disabled {
    color: #b3b3b3; }
  .primary-cta:hover {
    background-color: #c44eb9; }
  .primary-cta:active {
    background-color: #c44eb9; }
  .primary-cta:disabled {
    opacity: 0.4; }

.tertiary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #c44eb9; }
  .tertiary-cta:disabled {
    color: #b3b3b3; }
  .tertiary-cta:hover {
    text-decoration: underline; }
  .tertiary-cta:active {
    text-decoration: underline;
    color: #c44eb9; }

.tertiary-red-cta {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #da1e28; }
  .tertiary-red-cta:disabled {
    color: #b3b3b3; }
  .tertiary-red-cta:hover {
    text-decoration: underline; }
  .tertiary-red-cta:active {
    text-decoration: underline;
    color: #c44eb9; }

.secondary-cta {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #2a192d;
  border-radius: 4px;
  border: 1px solid #2a192d;
  color: #2a192d;
  font-weight: 600; }
  .secondary-cta:disabled {
    color: #b3b3b3; }
  .secondary-cta:hover {
    color: #c44eb9;
    border: 2px solid #c44eb9; }
  .secondary-cta:active {
    border: 1px solid #c44eb9;
    color: #c44eb9; }
  .secondary-cta:disabled {
    opacity: 0.4; }

/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
/*Theme Colors*/
/*Borders*/
.input-label {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #363430;
  margin-bottom: 10px;
  display: block;
  text-align: left; }

.input-field {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #323232;
  background: transparent;
  border-bottom: 1px solid #747474; }
  .input-field::placeholder {
    color: #747474; }

.input-field-box {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: #323232;
  border-radius: 104px;
  padding: 8px 24px;
  border: 1px solid #e7e6e5;
  background: #fafafa; }
  .input-field-box::placeholder {
    color: #747474; }
  .input-field-box:disabled {
    background: #f8f8f8;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.07); }

.search-field-box {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #323232;
  background: #ffffff;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 4px; }
  .search-field-box::placeholder {
    color: #747474; }

.input-dropdown-box {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #323232;
  background: white;
  border-radius: 4px;
  border: 1px solid #e1e1e1; }
  .input-dropdown-box::placeholder {
    color: #747474; }
  .input-dropdown-box:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer; }
  .input-dropdown-box:active, .input-dropdown-box:focus {
    border: 1px solid #747474;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); }

.input-field-box-dotted {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #323232;
  background: #ffffff;
  border: 2px dashed #747474;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2); }
  .input-field-box-dotted::placeholder {
    color: #747474; }

.input-field-box-selected {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #2a192d;
  border-radius: 104px;
  padding: 8px 24px;
  border: 1px solid #2a192d;
  background: #e9fce8; }
  .input-field-box-selected::placeholder {
    color: #747474; }

.input-field-box-selected-dark {
  background: #323232;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: white;
  font-weight: 700; }

.input-field-box-selected-custom {
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: white;
  font-weight: 700; }

.input-field-box-selected-blue {
  background: #1e2447;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: white;
  font-weight: 700; }

input:focus,
textarea:focus,
button:focus {
  outline-style: none; }

.theme-input {
  background: white;
  border-radius: 4px;
  border: 1px solid #e1e1e1; }
  .theme-input:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer; }
  .theme-input:active, .theme-input:focus {
    border: 1px solid #747474;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); }

/* Interaction for hover on card -> the arrow expands */
/*Theme Colors*/
/*Borders*/
.yoda-animation {
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite; }

.insta-stories-wrapper > div {
  width: 100vw !important;
  height: 100vh !important;
  background-color: transparent !important; }

.insta-stories-wrapper > div > div:nth-child(2) {
  width: 100vw !important;
  height: 100vh !important; }

.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden; }
  .hero .hero-bg-imgs {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 1vw;
    width: 100%;
    height: 100%;
    opacity: 0.25; }
    .hero .hero-bg-imgs .flex-wrap {
      display: flex;
      min-width: 0;
      min-height: 0; }
      .hero .hero-bg-imgs .flex-wrap > * {
        flex: 0 0 100%;
        min-width: 0; }
    .hero .hero-bg-imgs img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s ease-in; }
      .hero .hero-bg-imgs img.js-fade-in {
        opacity: 1; }

.hero-title {
  position: relative;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-in; }
  .hero-title.js-fade-in {
    opacity: 1; }

/*Theme Colors*/
/*Borders*/
.top-nav {
  background: #f6c0f1;
  background: linear-gradient(133deg, #f6c0f1 0%, #ffe0fc 100%); }

.tapbar {
  background: #fff;
  mix-blend-mode: normal;
  box-shadow: 0px -10px 20px -13px rgba(0, 0, 0, 0.15); }

.side-nav {
  background: #f6c0f1;
  background: linear-gradient(133deg, #f6c0f1 0%, #ffe0fc 100%);
  max-width: 252px;
  width: 100%; }

.menuitem {
  font-family: "Karla", sans-serif;
  font-size: 14px;
  color: #323232;
  border-right: 5px solid transparent;
  padding: 22px 28px; }
  .menuitem:hover {
    background: rgba(48, 23, 9, 0.1);
    color: #2a192d;
    background: #fff;
    border-radius: 25px 0px 0px 25px; }
  .menuitem.active {
    font-weight: 600;
    color: #2a192d;
    background: #fff;
    border-radius: 25px 0px 0px 25px; }

/*Theme Colors*/
/*Borders*/
/*Cube*/
.animated-cube {
  animation: hue-rotate 3s linear infinite; }

@keyframes hue-rotate {
  from {
    -webkit-filter: hue-rotate(0);
    -moz-filter: hue-rotate(0);
    -ms-filter: hue-rotate(0);
    filter: hue-rotate(0); }
  to {
    -webkit-filter: hue-rotate(360deg);
    -moz-filter: hue-rotate(360deg);
    -ms-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg); } }

/* Logo*/
.otp-login-container .title-font {
  font-family: Poppins;
  font-weight: 700;
  color: #f2c0f2; }

.otp-login-container .caption-font {
  font-family: "Lato", sans-serif;
  font-size: 2em;
  font-weight: 700;
  color: white; }

.otp-login-container form {
  padding: 25px;
  margin-right: auto;
  margin-left: auto;
  max-width: 23.75rem;
  background: #f6c0f1;
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  font-family: "Lato", sans-serif; }

.otp-login-container input,
.otp-login-container textarea {
  display: block;
  padding: 0.5rem 0.75rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: #55595c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #eee;
  border-left: 0;
  border-radius: 3px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1); }

.otp-login-container input:focus,
.otp-login-container textarea:focus {
  outline: 0;
  border-bottom-color: #f2c0f2; }

.otp-login-container textarea {
  resize: vertical; }

.otp-login-container .btn {
  display: inline-block;
  padding: 0.75rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);
  min-width: 160px; }

.otp-login-container .btn:focus,
.otp-login-container .btn:hover {
  /* box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07); */
  background-color: #2a192d !important;
  color: #f6c0f1 !important; }

.otp-login-container .btn:focus {
  outline: 0; }

.otp-login-container body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.purple-bg {
  background: linear-gradient(252.44deg, #4e1882 0%, #300964 100%), #9929ea;
  background-size: cover; }

.login-bg {
  background-color: #fff; }

.otp-login-container .font-purple {
  color: #4e1882; }

.otp-login-container .light-purple-bg {
  background-color: #f2c0f2; }

.border-chronos {
  border-color: rgba(221, 214, 254, 0.15) !important; }

/* Form */
.glass-form {
  background: rgba(254, 214, 210, 0.2) !important;
  box-shadow: 0 8px 32px 0 rgba(254, 214, 210, 0.35) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(254, 214, 210, 0.15) !important; }

.glass-modal-header {
  background: rgba(254, 214, 210, 0.25) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  -moz-backdrop-filter: blur(5px) !important; }
